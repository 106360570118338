import React, { useRef, useState, useCallback } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { useWindowSize } from 'usehooks-ts';

import { useElasticSearchKeyCategories } from '@/modules/core/hooks/useElasticSearchServer';

const items = [
  {
    id: '60be84bc50ab95b45b08a094', // Véhicules
    link: 'vehicles',
    linkFr: 'Véhicules',
    DesktopImage: '/media/cars-carousel-desktop.webp',
    label: 'Véhicules',
    circle:
      'bg-[#FDC305]/50 hover:translate-x-3 hover:translate-y-3 top-7 left-4',
  },
  {
    id: '60be84bc50ab95b45b08a093', // Immobilier
    link: 'real-estate',
    linkFr: 'Immobilier',
    DesktopImage: '/media/realestate-carousel-desktop.webp',
    label: 'Immobilier',
    circle:
      'bg-[#39B889]/50 hover:translate-x-3 hover:translate-y-0 top-8 md:top-10 right-5  md:right-8',
  },
  {
    id: '66c34239d16d7f3b0c2ec56e', // Immobilier Neuf (assuming it's a subcategory of Immobilier)
    link: 'ImmoNeuf',
    linkFr: 'ImmoNeuf',
    DesktopImage: '/media/key-desktop.webp',
    label: 'ImmoNeuf',
    circle:
      'bg-[#065797]/50 hover:translate-x-0 hover:translate-y-3 right-7 top-8',
  },
  {
    id: '60be84bc50ab95b45b08a097', // Multimédias
    link: 'tech',
    linkFr: 'Informatique et Multimedia',
    DesktopImage: '/media/multimedia-carousel-desktop.webp',
    label: 'Informatique et Multimedia',
    circle:
      'bg-[#39DEEE]/50 hover:translate-x-3 hover:translate-y-3 top-6 left-10 lg:left-8',
  },
  {
    id: '60be84bc50ab95b45b08a095', // Maison & jardin
    link: 'home-and-garden',
    linkFr: 'Pour la Maison et Jardin',
    DesktopImage: '/media/home-carousel-desktop.webp',
    label: 'Maison et Jardin',
    circle: 'bg-[#CF4032]/50 hover:translate-x-0 hover:translate-y-3 top-5',
  },
  {
    id: '60be84bc50ab95b45b08a096', // Loisirs
    link: 'leisure-and-entertainment',
    linkFr: 'Loisirs et Divertissement',
    DesktopImage: '/media/loisir-carousel-desktop.webp',
    label: 'Loisirs et Divertissement',
    circle:
      'bg-[#FADBD3]/50 hover:-translate-x-3 hover:translate-y-3 top-6 right-6',
  },
  {
    id: '60be84bc50ab95b45b08a099', // Habillement
    link: 'clothing-and-well-being',
    linkFr: 'Habillement et Bien Etre',
    DesktopImage: '/media/clothes-carousel-desktop.webp',
    label: 'Habillement et Bien Etre',
    circle:
      'bg-[#404EBB]/50 hover:translate-x-0 hover:translate-y-3 top-6 left-6',
  },
  {
    id: '60be84bc50ab95b45b08a098', // Emplois
    link: 'jobs-and-services',
    linkFr: 'Emploi et Services',
    DesktopImage: '/media/jobs-carousel-desktop.webp',
    label: 'Emploi et Services',
    circle:
      'bg-[#979595]/50 hover:translate-x-3 hover:translate-y-3 top-10 left-4',
  },
  {
    id: '60be84bd50ab95b45b08a09a', // Entreprises
    link: 'Entreprises',
    linkFr: 'Entreprises',
    DesktopImage: '/media/entreprise-carousel-desktop.webp',
    label: 'Entreprises',
    circle:
      'bg-[#F48D83]/50 hover:translate-x-0 hover:translate-y-3 top-2 left-6',
  },
];
const CategoriesCarousel = React.memo(() => {
  const [handleCategoryClick] = useElasticSearchKeyCategories();
  const carouselRef = useRef<HTMLDivElement>(null);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);
  const { width: windowWidth } = useWindowSize();

  const handlePrevClick = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  }, []);

  const handleNextClick = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  }, []);

  const handleScroll = useCallback(() => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
      const isEnd = scrollLeft + clientWidth >= scrollWidth;

      setShowPrevButton(scrollLeft > 0);
      setShowNextButton(!isEnd);
    }
  }, []);

  const renderCarouselItem = (item: (typeof items)[0]) => (
    <div
      key={item.id}
      className="flex flex-col m-1 items-center justify-between  relative"
    >
      <Link
        href={{
          pathname: `/ads${item.linkFr ? `/c/${item.linkFr}` : ''}`,
        }}
        replace
        passHref
        className="flex mx-auto justify-center items-end w-max lg:w-40 h-20"
        onClick={() => {
          if (item.link !== '') {
            handleCategoryClick(item.link);
          }
        }}
      >
        <div
          className={`absolute w-8 h-8 md:w-10 md:h-10 rounded-full ${item.circle} hover:transition ease-in duration-300 cursor-pointer transition-transform duration-600`}
        />
        <Image
          loading="lazy"
          width={100}
          height={100}
          className=" transition-all duration-300"
          src={item.DesktopImage}
          unoptimized
          alt={`catégorie ${item.label}`}
        />
      </Link>
      <Link
        href={item.link}
        className="text-center mt-4 truncate text-sm md:text-base font-extrabold text-neutral-800"
        replace
        passHref
      >
        {item.label}
      </Link>
    </div>
  );

  return (
    <div className="w-full flex flex-col lg:my-4">
      <h2 className="text-lg md:text-xl font-extrabold">
        Explorez toutes les catégories
      </h2>

      <div className="w-full justify-center items-center overflow-hidden relative lg:my-2">
        <div
          ref={carouselRef}
          className="w-full flex justify-between items-center pl-2 md:pl-0 gap-x-4 md:gap-x-5 overflow-hidden"
          onScroll={handleScroll}
        >
          {items.map(renderCarouselItem)}
        </div>
        {windowWidth < 1900 && (
          <>
            <div className="absolute top-5 -right-1 md:right-0 px-8 md:px-10 h-[160px] bg-gradient-to-l from-neutral-50 z-40">
              {showNextButton && (
                <button
                  className="absolute top-[35%] transform -translate-y-1/2 right-2 bg-white border border-neutral-400 p-2 md:p-4 rounded-full text-neutral-700 hover:text-neutral-900 focus:outline-none focus:bg-neutral-110 z-50"
                  onClick={handleNextClick}
                >
                  <MdArrowForwardIos size={20} />
                </button>
              )}
            </div>
            <div className="absolute top-5 -left-1 px-8 md:px-10 h-[160px] bg-gradient-to-r from-neutral-50 z-40">
              {showPrevButton && (
                <button
                  className="absolute top-[35%] transform -translate-y-1/2 left-2 bg-white border border-neutral-400 p-2 md:p-4 rounded-full text-neutral-700 hover:text-neutral-900 focus:outline-none focus:bg-neutral-110 z-50"
                  onClick={handlePrevClick}
                >
                  <MdArrowBackIosNew size={20} />
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
});

CategoriesCarousel.displayName = 'CategoriesCarousel';

export default CategoriesCarousel;
